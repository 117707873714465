import './App.css';
import Pages from './components/Pages';
import "./fonts/BDBID__.TTF";
function App() {
  return (
    <div className="App">
    
 
    <Pages />


    </div>
  );
}

export default App;
