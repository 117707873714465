import React from 'react';
import ContactUs from '../ContactUs';

const ContactPage = () => {
  return (
    <div className="fullscreen contactPageContainer">
        <h1 className="contactheader">Get in touch</h1>
      <ContactUs/>
    </div>
  )
}

export default ContactPage